import List from "list.js";
import LazyLoad from "vanilla-lazyload";
import PhotoSwipeLightbox from 'photoswipe/lightbox';

// --------------------------------------------------
// 💤 Lazy Loading
// --------------------------------------------------

var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
  elements_selector: "[data-lazy]"
});

///
/// List
/// 

var options = {
  valueNames: [ 'code', 'author', 'title', 'editorial', 'collection', 'city', 'country', 'year', 'pages', 'language' ]
};

var booksList = new List('books', options);

//////
////// Image gallery for films
//////

const lightbox = new PhotoSwipeLightbox({
  gallery: 'tbody.list',
  children: 'a',
  showHideAnimationType: 'none',
  bgOpacity: 1,
  mainClass: 'pswp--custom-bg',
  // mainClass: 'pswp--custom-icon-colors',
  pswpModule: () => import('photoswipe')
});
lightbox.init();


///
/// PROJECT IMAGE CAROUSEL
/// 

var bannercarousel = document.querySelector('.banner-carousel');
if (bannercarousel) {
  // largegallery.classList.remove('is-hidden');
  // trigger redraw for transition
  //largegallery.offsetHeight;

	// Initialize Flickity ///////////////////////////////////////////

  var bannerFlkty = new Flickity( '.banner-carousel', {
    // imagesLoaded: true,
    percentPosition: false,
    cellAlign: 'left',
    pageDots: false,
    prevNextButtons: false,
		lazyLoad: 1,
    cellSelector: '.banner-carousel-cell',
    fade: true,
    wrapAround: true,
    dragThreshold: 10
  });

	// previous
	var previousButtonbanner = document.querySelector('.button-previous-banner');
	// Added this if statement to check that the selector is present
	if(previousButtonbanner) {
	//  
	previousButtonbanner.addEventListener( 'click', function() {
	bannerFlkty.previous();
	})
	//
	};

	// next
	var nextButtonbanner = document.querySelector('.button-next-banner');
	// Added this if statement to check that the selector is present
	if(nextButtonbanner) {
	//  
	nextButtonbanner.addEventListener( 'click', function() {
	bannerFlkty.next();
	})
	//
	};

	var bannerFlkty = new Flickity('.banner-carousel');
	var carouselbannerStatus = document.querySelector('.banner-carousel-status');

	function updatebannerStatus() {
		var slidebannerNumber = bannerFlkty.selectedIndex + 1;
		carouselbannerStatus.textContent = slidebannerNumber + '/' + bannerFlkty.slides.length;
	}
	updatebannerStatus();

	bannerFlkty.on( 'select', updatebannerStatus );

};

///
/// PROJECT LOWER IMAGE CAROUSEL
/// 

var lowercarousel = document.querySelector('.lower-carousel');
if (lowercarousel) {
  // largegallery.classList.remove('is-hidden');
  // trigger redraw for transition
  //largegallery.offsetHeight;

	// Initialize Flickity ///////////////////////////////////////////

  var lowerFlkty = new Flickity( '.lower-carousel', {
    // imagesLoaded: true,
    percentPosition: false,
    cellAlign: 'left',
    pageDots: false,
    prevNextButtons: false,
		lazyLoad: 2,
    cellSelector: '.lower-carousel-cell',
    fade: true,
    wrapAround: true,
    adaptiveHeight: true,
    dragThreshold: 10
  });

	// previous
	var previousButtonlower = document.querySelector('.button-previous-lower');
	// Added this if statement to check that the selector is present
	if(previousButtonlower) {
	//  
	previousButtonlower.addEventListener( 'click', function() {
	lowerFlkty.previous();
	})
	//
	};

	// next
	var nextButtonlower = document.querySelector('.button-next-lower');
	// Added this if statement to check that the selector is present
	if(nextButtonlower) {
	//  
	nextButtonlower.addEventListener( 'click', function() {
	lowerFlkty.next();
	})
	//
	};

	var lowerFlkty = new Flickity('.lower-carousel');
	var carousellowerStatus = document.querySelector('.lower-carousel-status');

	function updatelowerStatus() {
		var slidelowerNumber = lowerFlkty.selectedIndex + 1;
		carousellowerStatus.textContent = slidelowerNumber + '/' + lowerFlkty.slides.length;
	}
	updatelowerStatus();

	lowerFlkty.on( 'select', updatelowerStatus );

};

///
/// INCAUTADOS COVER CAROUSEL
/// 

var bannerCoversCarousel = document.querySelector('.banner-covers-carousel');
if (bannerCoversCarousel) {
  // largegallery.classList.remove('is-hidden');
  // trigger redraw for transition
  //largegallery.offsetHeight;

	// Initialize Flickity ///////////////////////////////////////////

  var bannerFlkty = new Flickity( '.banner-covers-carousel', {
    percentPosition: false,
    cellAlign: 'left',
    pageDots: false,
    prevNextButtons: false,
		lazyLoad: 1,
    cellSelector: '.banner-covers-carousel-cell',
    fade: true,
    wrapAround: true,
    groupCells: 3,
    autoPlay: 3500,
    pauseAutoPlayOnHover: false,
    draggable: false
  });

};

///
/// Book Video
///
var bannerBookVideo = document.querySelector('.banner-book-video');
if (bannerBookVideo) {

const options = {
  loop: true,
  background: true
};
const vimeo_player = new Vimeo.Player('myBookVideo', options);
//When the player is ready, set the volume to 0
//Google policy
vimeo_player.ready().then(function() {
  vimeo_player.setVolume(0);
  vimeo_player.play();
  // removeVideoCover();
});

// function removeVideoCover() {
//  body.querySelector('#video-cover').addClass("hide");
// }

//check video percentage and update time accordingly
setInterval(() => {
  var position = vimeo_player.getCurrentTime().then(function(seconds) {
  positionModified = new Date(seconds * 1000).toISOString().substring(14, 19);
  body.querySelector(".video-time").textContent = positionModified;
  }).catch(function(error) {
      // an error occurred
  });
  var duration = vimeo_player.getDuration().then(function(duration) {
  durationModified = new Date(duration * 1000).toISOString().substring(14, 19);
  body.querySelector(".video-duration").textContent = durationModified;
  }).catch(function(error) {
      // an error occurred
  });
}, 100);

};

// --------------------------------------------------
// ⌛ Page loading animation
// --------------------------------------------------

let changingText = document.querySelector('.spinner');

if (changingText) {
  // Text options
  const spinnerCharacters = [
    ".",
    "..",
    "...",
    "..",
    ".",
    "",
    "",
  ];

  let currentIndex = 0;

  // Function to change the text
  function changeText() {
    changingText.textContent = spinnerCharacters[currentIndex];
    currentIndex = (currentIndex + 1) % spinnerCharacters.length;
  }

  // Call the changeText function every 2 seconds (2000 milliseconds)
  setInterval(changeText, 200);

}